import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import articles from '../../api/articles.json';
import SEO from '../../components/SEO';
import styles from './Article.module.css';

const Article = () => {
  const { id } = useParams<{ id: string }>();
  const article = articles.find((article) => article.id === +id!);

  // Preprocess the links object to filter out invalid or undefined entries
  const preprocessLinks = (links?: { [key: string]: any }) => {
    if (!links || typeof links !== 'object') return {};
    return Object.fromEntries(
      Object.entries(links).filter(
        ([, value]) =>
          value &&
          typeof value === 'object' &&
          value.url &&
          typeof value.url === 'string'
      )
    );
  };

  // Function to replace link markers in text with clickable links or images, with strict size control
  const renderTextWithLinks = (
    text: string,
    links: { [key: string]: { url: string; linkText?: string; imageSrc?: string; width?: string; height?: string; maxWidth?: string; maxHeight?: string } } = {}
  ) => {
    const linkRegex = /\[(link\d+)\]/g;

    return text.split(linkRegex).map((part, index) => {
      const link = links[part];
      if (link) {
        const { url, linkText, imageSrc, width = '100px', height = 'auto', maxWidth = '100%', maxHeight = 'auto' } = link;
        const isExternal = url?.startsWith('http');

        // Render video if imageSrc points to an .mp4 file
        if (imageSrc && imageSrc.endsWith('.mp4')) {
          return (
            <video
              key={index}
              width={width}
              height={height}
              style={{ maxWidth, maxHeight, marginLeft: '180px' }}
              controls
            >
              <source src={imageSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          );
        }

        // Render image if imageSrc is provided
        if (imageSrc) {
          return (
            <a key={index} href={url} target={isExternal ? '_blank' : '_self'} rel={isExternal ? 'noopener noreferrer' : undefined}>
              <img
                src={imageSrc}
                alt={linkText || 'Linked image'}
                style={{
                  width,
                  height,
                  maxWidth,
                  maxHeight,
                  objectFit: 'contain',
                }}
              />
            </a>
          );
        }

        // Default clickable link rendering
        return (
          <a key={index} href={url} target={isExternal ? '_blank' : '_self'} rel={isExternal ? 'noopener noreferrer' : undefined}>
            {linkText || url}
          </a>
        );
      }
      return part;
    });
  };

  if (!article) {
    return <p>Article not found.</p>;
  }

  return (
    <>
      <SEO
        title="Article - Explore HedgeCoins Insights"
        description="Read detailed articles and insights on HedgeCoins, cryptocurrency trends, blockchain innovations, and market updates."
        keywords="HedgeCoins articles, cryptocurrency insights, blockchain trends, market updates"
      />
      <section>
        <div className={styles.wrapper}>
          <h1 style={{ position: "absolute", visibility: "hidden" }}>
            Article - Explore HedgeCoins Insights
          </h1>
          <div className={styles.breadcrumbs}>Home &gt; News &gt; {article.header}</div>
          <div className={styles.imgContainer}>
            <img className={styles.img} src={article.labelImage} alt="img" />
            <span
              className={styles.articleDate}
              style={{
                position: 'absolute',
                top: '-00px',
                right: '90px',
                padding: '2px 5px',
                borderRadius: '4px',
              }}
            >
              {moment(article.date).format('DD MMM YYYY')}
            </span>
          </div>
        </div>
        <div className={styles.innerWrapper}>
          <div style={{ margin: '0 auto' }}>
            <h2 className={styles.heading}>{article.header}</h2>
            {article.intro1.map((text, index) => (
              <p key={index} className={styles.subHeading}>
                {text}
              </p>
            ))}
            {article.intro2.map((text, index) => (
              <p key={index} className={styles.subHeading}>
                {text}
              </p>
            ))}

            <span className={styles.by}>By {article.by}</span>
            {/* Rendering each section with headings, texts, and replacing link markers */}
            {article.headings.map((heading, index) => (
              <div key={index}>
                <h6 className={styles.smallHeading}>{heading.heading}</h6>
                {heading?.texts.map((text, idx) => (
                  <p key={idx} className={styles.text}>
                    {renderTextWithLinks(text, preprocessLinks(heading.links))}
                  </p>
                ))}
              </div>
            ))}
            <Link className={styles.link} to="/disclaimer">
              Terms and Conditions
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Article;
