import { useMediaQuery } from 'react-responsive';
import styles from './Compass.module.css';
import SEO from '../../components/SEO';

const Compass = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <>
      <SEO
        title="HEDGE Compass: Your Bridge to Hedge Fund Success"
        description="Discover HEDGE Compass, a groundbreaking cryptocurrency bridging the gap between users and the exclusive world of hedge funds with superior performance strategies."
        keywords="HEDGE Compass, cryptocurrency, hedge funds, directional hedge funds, crypto investment, stable coins"
      />
      <section>
        <h1 style={{ position: "absolute", visibility: "hidden" }}>
          HEDGE Compass: Your Bridge to Hedge Fund Success
        </h1>
        <div className={styles.colorWrapper}>
          <img className={styles.img} src='/compass-page.png' alt='compass' />
          <div className={styles.breadcrumbs}>Home &gt; Coins &gt; Compass</div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
            <div>
              <h2 className={styles.heading}>BREAKING BARRIERS:</h2>
              <h2 className={styles.heading2}>
                Your entry to hedge fund success
              </h2>
            </div>
            <div>
              <h3 className={styles.subHeading}>Introducing HEDGE Compass</h3>
              <h6 className={styles.text}>
                A game-changing cryptocurrency poised to revolutionize the world
                of cryptocurrencies.
              </h6>
            </div>
          </div>
        </div>
        <div className={styles.innerWrapper}>
          <div className={styles.card}>
            <img src='/compass.gif' alt='compass' />
            <div style={{ maxWidth: '500px' }}>
              <p className={styles.cardTitle}>
                Breaking down barriers, HEDGE Compass bridges the gap between
                cryptocurrency users and the exclusive world of hedge funds
                traditionally reserved for the select few.
              </p>
              <div className={styles.border} />
              <p className={styles.cardText}>
                Get ready to redefine your crypto journey with the unique and
                exclusive power of hedge funds.
              </p>
              <div className={styles.border} />
              <p className={styles.cardText}>
                HEDGE Compass leverages the strategies of “directional" hedge
                funds, capturing market trends both up and down, to empower users
                with the potential for superior performance.
              </p>
            </div>
          </div>
          <p className={styles.howItWorks}>How it works</p>
          <h3 className={styles.howItWorksHeading}>
            HEDGE Compass introduces a fascinating blend of stability and
            opportunity within the crypto space.
          </h3>
          <div className={styles.howItWorksWrapper}>
            <p className={styles.howItWorksText}>
              Hedge Compass tokens are acquired by exchanging stable coins, such
              as USD Tether, ensuring a secure point of entry into the ecosystem.
              When individuals purchase HEDGE Compass tokens, all funds generated
              are held in a dedicated reserve hosted by our treasury. The treasury
              then dynamically allocates these reserved funds into a diverse range
              of “directional” hedge funds, each selected for their potential to
              optimize returns.
              <br />
              {isMobile && (
                <img
                  src='/graphic-mob-compass.svg'
                  alt='floor price'
                  style={{ margin: '48px 0' }}
                />
              )}
              <br /> The floor price is the price at which HEDGE
              Compass tokens can be bought and redeemed for USD stablecoins. The floor price is determined by the value of the reserve and the number of
              HEDGE Compass tokens in circulation. As the value of the reserve
              changes over time depending on the performance of the hedge funds,
              so will the floor price.
            </p>
            {!isMobile && (
              <img src='/graphic-desc-compass.svg' alt='floor price' />
            )}
          </div>
          <div
            style={{
              display: isMobile ? 'flex' : 'block',
              justifyContent: isMobile ? 'center' : 'initial',
              marginBottom: isMobile ? '62px' : '97px',
            }}>
            <img
              src={isMobile ? '/Compass-mob.svg' : '/Compass-desk.svg'}
              alt='Schema compass'
            />
          </div>
          <div>
            <h3 className={styles.bottomHeading}>
              What is a “directional” Hedge Fund?
            </h3>
            <h4 className={styles.bottomSubHeading}>
              Like all hedge funds, directional hedge funds are only available to
              the select few individuals and corporations with enough financial
              resources and technical knowledge to meet the strict entry criteria.{' '}
            </h4>
            <p className={styles.bottomText}>
              These funds use advanced strategies and market trading techniques
              not typically available to normal investment funds. Most notably,
              “directional” hedge funds are able to profit in downward trending
              markets using a strategy known as “shorting”. To “short” the market,
              a hedge fund borrows assets to sell when the price for that asset is
              high or inflated only to repurchase the same asset at a lower price
              in the future.
              The borrowed assets are then retuned and the hedge fund has
              made a tidy profit from the falling price. This ability to profit
              from both upward trending as well as downward trending markets is
              why hedge funds that employ these strategies are often referred to
              as “directional” hedge funds.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Compass;
