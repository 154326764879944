// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_container__1fusd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  color: #333;
  padding: 20px;
}

.NotFound_header__qpXs7 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.NotFound_message__obVKA {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.NotFound_link__D0B5M {
  text-decoration: none;
  color: #007bff;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 10px 20px;
  border: 2px solid #007bff;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.NotFound_link__D0B5M:hover {
  background-color: #007bff;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFound/NotFound.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,6CAA6C;AAC/C;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  text-align: center;\n  background-color: #f8f9fa;\n  color: #333;\n  padding: 20px;\n}\n\n.header {\n  font-size: 3rem;\n  font-weight: bold;\n  margin-bottom: 1rem;\n}\n\n.message {\n  font-size: 1.5rem;\n  margin-bottom: 2rem;\n}\n\n.link {\n  text-decoration: none;\n  color: #007bff;\n  font-size: 1.25rem;\n  font-weight: 600;\n  padding: 10px 20px;\n  border: 2px solid #007bff;\n  border-radius: 5px;\n  transition: background-color 0.3s, color 0.3s;\n}\n\n.link:hover {\n  background-color: #007bff;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NotFound_container__1fusd`,
	"header": `NotFound_header__qpXs7`,
	"message": `NotFound_message__obVKA`,
	"link": `NotFound_link__D0B5M`
};
export default ___CSS_LOADER_EXPORT___;
