import { useEffect, useRef } from "react";
import NewsCard from "../../components/NewsCard/NewsCard";
import ArrowRight from "../../icons/ArrowRight";
import styles from "./Home.module.css";
import BigCryptoCard from "./components/BigCryptoCard/BigCryptoCard";
import CryptoCard from "./components/CryptoCard/CryptoCard";

import moment from "moment";
import articles from "../../api/articles.json";

import Lottie from "lottie-web";
import { useSelector } from "react-redux";
import SEO from "../../components/SEO";
import { useGetFloorPriceQuery } from "../../services/Auth";
import CustomTypeIt from "../../components/TypeIt/TypeIt";

const Home = () => {
  const animationElement = useRef<any>();
  const blockChainNetwork = useSelector((state: any) => state.global.network);
  const { data: getFloorPrice } = useGetFloorPriceQuery({ network: blockChainNetwork.toUpperCase() });

  articles.sort((a, b) => {
    return moment(b.date).diff(moment(a.date));
  });

  useEffect(() => {
    let animation = Lottie.loadAnimation({
      container: animationElement.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/animation.json",
    });
    return () => animation.destroy();
  }, []);

  return (
    <>
      <SEO
        title="Home"
        description="Explore HedgeCoins - breaking barriers and revolutionizing cryptocurrency investment through hedge fund strategies."
        keywords="HedgeCoins, Cryptocurrency, Hedge Funds, Investment, Blockchain"
      />
      <section className={styles.sectionWrapper}>
        <div className={styles.cryptocurrencyWrapper}>
          <div className={styles.heading}>
            <h1 style={{ position: "absolute", visibility: "hidden" }}>
              HedgeCoins: A new type of cryptocurrency - Breaking barriers - Your entry to hedge fund success
            </h1>
            <CustomTypeIt
              strings={[
                "A new type of cryptocurrency",
                "Breaking barriers",
                "Your entry to hedge fund success",
              ]}
              element="h2"
              speed={55}
              pause={1500}
              loop={true}
            />
          </div>
          <div className={styles.background} ref={animationElement} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "72px",
            }}
          >
            <CryptoCard
              src="/C_with_dash.svg"
              value={`$ ${getFloorPrice?.data?.toString()}`}
              status={
                <div style={{ display: "flex", alignItems: "center" }}>

                </div>
              }
            />
            <CryptoCard
              src="/A_with_dash.svg"
              status={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ArrowRight
                    style={{
                      marginRight: "12px",
                    }}
                  />
                  <span
                    style={{
                      color: "#883038",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    Coming soon
                  </span>
                </div>
              }
            />
          </div>
          <h4 className={styles.subheading}>How it works</h4>
          <div className={styles.howItWorksList}>
            <div className={styles.howItWorksItem}>
              <img
                style={{ marginBottom: "35px" }}
                src="all-funds.svg"
                alt="img"
              />
              <p className={styles.howItWorksText}>
                All funds raised from the issue
                <br /> of HEDGE tokens are held in
                <br /> reserve by our treasury
              </p>
            </div>
            <div className={styles.howItWorksItem}>
              <img
                style={{ marginBottom: "35px" }}
                src="our-treasury.svg"
                alt="img"
              />
              <p className={styles.howItWorksText}>
                Our team strategically allocates
                <br />
                these funds into a targeted
                <br />
                portfolio of hedge funds
              </p>
            </div>
            <div className={styles.howItWorksItem}>
              <img
                style={{ marginBottom: "35px" }}
                src="the-value.svg"
                alt="img"
              />
              <p className={styles.howItWorksText}>
                The value of the reserve will determine
                <br />
                the floor price. All HEDGE tokens
                <br />
                are redeemable for other USD stable
                <br />
                coins at the floor price
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "150px",
          }}
        >
          <BigCryptoCard
            img="big-compass.svg"
            title="C_with_dash.svg"
            subTitle="The first HEDGE token! "
            text="All HEDGE Compass are allocated into 'directional' hedge funds. Directional hedge funds employ market direction trading strategies such as shorting to capitalise on both upward and downward trends."
            to="coins/compass"
            style={{ marginBottom: "150px", maxWidth: "1036px", width: "100%" }}
          />
          <BigCryptoCard
            img="big-arbitrage.svg"
            title="A_with_dash.svg"
            subTitle="Coming soon"
            to="coins/arbitrage"
            text="All HEDGE Arbitrage reserves are allocated into 'arbitrage' hedge funds. Arbitrage hedge funds are hedge funds that profit from price differences of a particular commodity or asset different markets around the globe."
            style={{ maxWidth: "1000px", width: "100%" }}
          />
        </div>
        <div className={styles.newsWrapper}>
          <h6 className={styles.news}>Latest news</h6>
          <div className={styles.newsList}>
            {articles.slice(0, 3).map((item) => (
              <NewsCard
                key={item.id}
                src={item.labelImage}
                to={`/news/article/${item.id}`}
                title={item.header}
                text={item.desc}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
