import { Link } from "react-router-dom";
import styles from "./NotFound.module.css";

const NotFound = () => (
  <div className={styles.container}>
    <h1 className={styles.header}>
      404 Error: The page You’re Looking for Doesn’t Exist
    </h1>
    <p className={styles.message}>
      Oops! The page you're looking for doesn't exist.
    </p>
    <Link to="/" className={styles.link}>
      Go Back to Home
    </Link>
  </div>
);

export default NotFound;