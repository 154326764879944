import { useState } from "react";
import ArrowRightMd from "../../icons/ArrowRightMd";
import styles from "./Buy.module.css";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import ConnectWalletModal from "../../components/ConnectWalletModal/ConnectWalletModal";
import { useGetFloorPriceQuery } from "../../services/Auth";
import SEO from "../../components/SEO";

const Buy = () => {
  const blockChainNetwork = useSelector((state: any) => state.global.network);
  const [activeClass, setActiveClass] = useState(styles.mouseLeave);
  const navigate = useNavigate();
  const { address } = useAccount();
  const [openModal, setOpenModal] = useState(false);
  const { data: getFloorPrice } = useGetFloorPriceQuery({ network: blockChainNetwork.toUpperCase() });

  const handleClick = () => {
    if (address) {
      navigate("/buy-balance");
    } else {
      toast.error("Please connect your wallet first");
      setOpenModal(true);
    }
  };

  return (
    <>
      <SEO
        title="Buy HEDGE Tokens - Secure and Easy Transactions"
        description="Purchase HEDGE tokens effortlessly at the current floor price. Join the future of cryptocurrency investment with HedgeCoins."
        keywords="Buy HEDGE tokens, cryptocurrency, secure transactions, blockchain, HedgeCoins"
      />
      <section className={styles.wrapper}>
        <h1 style={{ position: "absolute", visibility: "hidden" }}>
          Buy HEDGE Tokens Securely and Effortlessly
        </h1>
        <div className={styles.breadcrumbs}>Home &gt; Buy</div>
        <div>
          <h1 className={styles.heading}>Buy</h1>
          <h6 className={styles.subHeading}>
            You can always buy your HEDGE tokens at the current floor price.
          </h6>
          <div className={styles.cardsWrapper}>
            <div className={styles.cardWrapper}>
              <img
                style={{ marginBottom: "15px", width: "215px" }}
                src="/C_with_dash.svg"
                alt="compass"
              />
              <span className={styles.floorPrice}>Floor Price</span>
              <span className={styles.value}>$ {getFloorPrice?.data || "-"}</span>
            </div>

            <div className={styles.cardWrapper}>
              <img
                style={{ marginBottom: "15px", width: "215px" }}
                src="/A_with_dash.svg"
                alt="arbitrage"
              />
              <span className={styles.floorPrice}>Floor Price</span>
              <span className={styles.value}>Coming soon</span>
            </div>
          </div>
          <button
            className={`${styles.button} ${activeClass}`}
            onMouseEnter={() => setActiveClass(styles.mouseEnter)}
            onMouseLeave={() => setActiveClass(styles.mouseLeave)}
            onClick={() => handleClick()}
          >
            <span >
              {address ? "Buy" : "Connect to Buy"}
            </span>
            <ArrowRightMd />
          </button>
        </div>
        <ConnectWalletModal openModal={openModal} setOpenModal={setOpenModal} />
      </section>
    </>
  );
};

export default Buy;
