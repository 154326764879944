import React, { useState, useEffect, useRef } from 'react';

interface CustomTypeItProps {
  strings: string[];
  element?: keyof JSX.IntrinsicElements;
  speed?: number;
  loop?: boolean;
  initialDelay?: number;
  pause?: number;
  deleteSpeed?: number;
  placeholder?: string
}

const CustomTypeIt: React.FC<CustomTypeItProps> = ({
  strings,
  element = 'h2',
  speed = 55,
  loop = true,
  initialDelay = 0,
  pause = 1500,
  deleteSpeed = speed / 2,
  placeholder = '\u00A0', // Non-breaking space as a default placeholder
}) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentStringIndex, setCurrentStringIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [phase, setPhase] = useState<'typing' | 'pausing' | 'deleting' | 'complete'>('typing');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const currentString = strings[currentStringIndex];
  const Tag = element;

  useEffect(() => {
      if (phase === 'typing') {
          timeoutRef.current = setTimeout(() => {
              typeChar();
          }, speed);
      } else if (phase === 'deleting') {
          timeoutRef.current = setTimeout(() => {
              deleteChar();
          }, deleteSpeed);
      } else if (phase === 'pausing') {
          timeoutRef.current = setTimeout(() => {
              pausePhase();
          }, pause)
      }
      return () => {
          if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
          }
      }

  }, [displayedText, phase, charIndex]);


  useEffect(() => {

      if(phase === 'typing' && charIndex === 0 && initialDelay > 0) {
          timeoutRef.current = setTimeout(() => {
              typeChar();
          }, initialDelay)
      }

  }, [phase, initialDelay]);


    const typeChar = () => {
        if (charIndex < currentString.length) {
            setDisplayedText((prev) => prev + currentString[charIndex]);
            setCharIndex((prev) => prev + 1);
        } else {
            setPhase('pausing');
            setCharIndex(0);
        }
    };

    const deleteChar = () => {
        if (displayedText.length > 0) {
            setDisplayedText((prev) => prev.slice(0, -1));
        } else {
            if (currentStringIndex < strings.length - 1) {
                setCurrentStringIndex((prev) => prev + 1);
                setPhase('typing');
            } else {
                if (loop) {
                    setCurrentStringIndex(0);
                    setPhase('typing');
                } else {
                    setPhase('complete');
                }
            }
             setDisplayedText(placeholder); // Set placeholder when empty
            setCharIndex(0)
        }
    };


    const pausePhase = () => {
        setPhase('deleting');
    }


  return (
        <Tag>
            {displayedText === '' ? placeholder : displayedText}
        </Tag>
    );
};

export default CustomTypeIt;