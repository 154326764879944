import { useCallback, useEffect, useState } from "react";
import ArrowRightMd from "../../icons/ArrowRightMd";
import styles from "./Transfer.module.css";
import { useSelector } from "react-redux";
import {
  useAccount,
  useWriteContract,
  useWaitForTransactionReceipt,
} from "wagmi";
import toast from "react-hot-toast";
import styled from "styled-components";
import { Button, Form, Input } from "antd";
import {
  Allowance,
  ApproveDollar,
  DecimalValue,
  TokenBalance,
  bridgeToken,
} from "../../web3/Contracts/TokenMarketPlace";
import { useForm, useWatch } from "antd/es/form/Form";
import { config } from "../../config/config";
import ConnectWalletModal from "../../components/ConnectWalletModal/ConnectWalletModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import ERC20Abi from "../../web3/ABI/ERC20.json";
import { parseUnits } from "viem";
import SEO from "../../components/SEO";
const fromOptions = [
  {
    title: "BNB Chain",
    value: "bnb_chain_coin",
  },
  {
    title: "Stellar",
    value: "xlm",
  },
  {
    title: "Ripple",
    value: "xrp",
  },
  {
    title: "USDT",
    value: "usdt",
  },
];

const toOptions = [
  {
    title: "Avalanche",
    value: "avalanche_coin",
  },
  {
    title: "USDC",
    value: "usdc",
  },
  {
    title: "Bitcoin",
    value: "btc",
  },
];

const StyledButton = styled(Button)`
  &:hover {
    background-color: transparent !important;
    border: none !important;
    span {
      color: #0058a0 !important;
    }
  }
`;

export const StyledInput = styled(Input)``;

const Bridge = () => {
  const [form] = useForm();
  const [selectedFrom, setSelectedFrom] = useState(fromOptions[0].value);
  const [selectedTo, setSelectedTo] = useState(toOptions[0].value);
  const [activeClass, setActiveClass] = useState(styles.mouseLeave);
  const [loading, setLoading] = useState(false);
  const [receivedAmount, setReceivedAmount] = useState();
  const selectedConfig = useSelector(
    (state: any) => state.global.selectedConfig
  );
  const { writeContractAsync, data, isError, error } = useWriteContract();

  const { chainId, isConnected, address } = useAccount();
  const [maxToken, setMaxToken] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const owner = address;
  const contractAddress = selectedConfig.Hedge_Token_Address;
  const [decimals, setDecimals] = useState<any>({
    dollar: DecimalValue(selectedConfig.Dollar_Token_Address)?.decimalValue,
    hedge: DecimalValue(selectedConfig.Hedge_Token_Address)?.decimalValue,
  });
  const [AllowanceAmount, setAllowanceAmount] = useState(
    Allowance(contractAddress, owner, selectedConfig)?.allowance /
    10 ** decimals
  );
  const [allowanceCheck, setAllowanceCheck] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [isTransfer, setIsTransfer] = useState(false);
  const waitMetod = useWaitForTransactionReceipt({
    confirmations: 1,
    hash: `0x${data?.slice(2)}`,
  });

  let { refetch: refetchHedgePrice, value: hedgePrice } = TokenBalance({
    tokenAddress: selectedConfig.Hedge_Token_Address,
    userAddress: address,
  });
  let refetchAllowance = Allowance(
    contractAddress,
    owner,
    selectedConfig
  )?.refetchAllowance;
  let refetchDollarDecimals = DecimalValue(
    selectedConfig.Dollar_Token_Address
  )?.refetchDecimals;
  let refetchHedgeDecimals = DecimalValue(
    selectedConfig.Hedge_Token_Address
  )?.refetchDecimals;

  const send = useWatch("send", form);

  const getAllowanceValue = useCallback(async () => {
    let response = await refetchAllowance();
    return Promise.resolve(Number(response?.data));
  }, [refetchAllowance]);

  const getDecimalValue = useCallback(async () => {
    try {
      let responseDollar = await refetchDollarDecimals();
      let responseHedge = await refetchHedgeDecimals();
      return {
        dollarDecimals: Promise.resolve(responseDollar),
        hedgeDecimals: Promise.resolve(responseHedge),
      };
    } catch (err) {
      toast.error("something went wrong");
    }
  }, [refetchHedgeDecimals, refetchDollarDecimals, selectedConfig]);

  useEffect(() => {
    getAllowanceValue().then((data) => {
      if (data) {
        setAllowanceAmount(Number(data) / 10 ** decimals?.hedge);
      } else {
        setAllowanceAmount(Number(data));
      }
    });
  }, [getAllowanceValue, selectedConfig]);

  useEffect(() => {
    let timer = setInterval(() => {
      getAllowanceValue().then((data) => {
        if (data) {
          setAllowanceAmount(Number(data) / 10 ** decimals?.hedge);
        } else {
          setAllowanceAmount(Number(data));
        }
      });
    }, 2000);
    return () => clearInterval(timer);
  }, [getAllowanceValue, selectedConfig, decimals]);

  useEffect(() => {
    getDecimalValue().then((data) => {
      data?.dollarDecimals?.then((dollar) => {
        setDecimals((prev: any) => {
          return {
            ...prev,
            dollar: dollar?.data,
          };
        });
      });
      data?.hedgeDecimals?.then((hedge) => {
        setDecimals((prev: any) => {
          return {
            ...prev,
            hedge: hedge?.data,
          };
        });
      });
    });
  }, [getDecimalValue, selectedConfig]);

  const handleSendChange = (event: any) => {
    form.setFieldValue("receive", event.target.value);
    setReceivedAmount(event.target.value);
  };
  const handleChangeFrom = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFrom(e.target.value);
  };
  const handleChangeTo = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTo(e.target.value);
  };
  let denominator = 10 ** decimals?.hedge;
  useEffect(() => {
    setMaxToken(Number(hedgePrice) / denominator);
  }, [hedgePrice, denominator]);

  useEffect(() => {
    if (waitMetod.isSuccess && isApproved) {
      toast.success("Token Approved successfully");
      setAllowanceCheck(false);
      setLoading(false);
    } else if (waitMetod.isSuccess && isTransfer) {
      toast.success("Token Bridged successfully");
      setLoading(false);
      setIsTransfer(false);
    }
  }, [waitMetod.isSuccess, isApproved, isTransfer]);

  useEffect(() => {
    if (isError && error) {
      console.log(error?.message);
      const message = error?.message?.split(":");
      const messageEnd = message?.[1]?.split("\n");
      toast.error(
        "Transaction reverted due to: " +
        message?.[1]?.slice(0, messageEnd?.[1]?.length + 1) +
        " or User rejected the transaction!!"
      );
      setLoading(false);
      setIsTransfer(false);
    }
  }, [error, isError]);

  const handleClick = async (values: any) => {
    if (address) {
      try {
        setLoading(true);
        setIsTransfer(false);

        if (Number(AllowanceAmount) >= values?.send) {
          const amount = parseUnits(values.send, decimals?.hedge);
          const result = await bridgeToken({
            writeContract: writeContractAsync,
            amount,
            address,
            selectedConfig,
          });
          setIsTransfer(true);
          setTimeout(async () => {
            await refetchHedgePrice();
          }, 15000);
          form.resetFields();

          setIsApproved(false);
          setIsTransfer(true);
        } else {
          setLoading(true);
          setIsApproved(false);
          setAllowanceCheck(true);
          const amount = Number(config.MAX_APPROVAL) * 10 ** decimals?.hedge;
          const tokenAddress = selectedConfig.Hedge_Token_Address;
          const hashData = await ApproveDollar(
            writeContractAsync,
            amount,
            address,
            tokenAddress,
            selectedConfig,
            setLoading,
            setOpenConfirmationModal,
            ERC20Abi
          );
          setIsApproved(true);
          setOpenConfirmationModal(true);
        }
      } catch (err: any) {
        setLoading(false);
        setIsTransfer(false);

        setIsApproved(false);
        setOpenConfirmationModal(false);
      }
    } else {
      toast.error("Please connect your wallet first");
      setOpenModal(true);
    }
  };
  const handleBridgeModal = async () => {
    setLoading(true);
    setIsTransfer(false);
    const amount = Number(send) * 10 ** decimals?.hedge;
    try {
      const result = await bridgeToken({
        writeContract: writeContractAsync,
        amount,
        address,
        selectedConfig,
      });
      setIsTransfer(true);
      setTimeout(async () => {
        await refetchHedgePrice();
      }, 15000);
      form.resetFields();
      setAllowanceCheck(false);
      setIsApproved(false);
      setLoading(false);
      setOpenConfirmationModal(false);
    } catch (err) {
      setAllowanceCheck(false);
      setLoading(false);
      setIsTransfer(false);
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };

  return (
    <>
      <SEO
        title="Start Your HEDGE Token Transfer Now"
        description="Bridge and transfer your tokens securely across multiple blockchain networks with HedgeCoins."
        keywords="Transfer tokens, bridge tokens, blockchain, HedgeCoins, secure transfer"
      />

      <section className={styles.wrapper}>
        <h1 style={{ position: "absolute", visibility: "hidden" }}>
          Start Your HEDGE Token Transfer Now
        </h1>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginBottom: "15px",
          }}
        >
          <div className={styles.breadcrumbs}>Home &gt; Bridge</div>
        </div>
        <div>
          <div className={styles.cardsWrapper}>
            <div className={styles.innerWrapper}>
              <div className={styles.select}>
                <label className={styles.selectLabel}>From</label>
                <div style={{ position: "relative" }}>
                  <span
                    className={styles.selectField}
                    onChange={handleChangeFrom}
                  >
                    {chainId === 11155111 || chainId === 1
                      ? "Ethereum"
                      : "Binance"}
                  </span>
                </div>
              </div>
              <Form form={form} onFinish={handleClick}>
                <div className={styles.input}>
                  <div className={styles.sendLabelWrapper}>
                    <label className={styles.inputLabel}>Send</label>
                    <label className={styles.maxAmountLabel}>
                      Max:{Number(maxToken)?.toFixed(3)}
                    </label>
                  </div>
                  <Form.Item
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      width: "90%",
                    }}
                    name="send"
                  >
                    <StyledInput
                      style={{ padding: "10px 0px 10px 10px" }}
                      onChange={handleSendChange}
                      className={styles.inputField}
                      type="number"
                      placeholder="0.0"
                      onKeyDown={handleKeyDown}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </Form.Item>
                </div>
                <div className={styles.convert}>
                  <img
                    style={{ marginBottom: "15px", width: "41px" }}
                    src="/convert_icon.svg"
                    alt="convert_icon"
                  />
                </div>
                <div className={styles.select}>
                  <label
                    className={styles.selectLabel}
                    style={{ marginRight: "10px" }}
                  >
                    To
                  </label>
                  <div style={{ position: "relative" }}>
                    <span
                      className={styles.selectField}
                      onChange={handleChangeTo}
                    >
                      {chainId === 11155111 || chainId === 1
                        ? "Binance"
                        : "Ethereum"}
                    </span>
                  </div>
                </div>
                <div className={styles.input}>
                  <div className={styles.receiveLabelWrapper}>
                    <img
                      style={{ marginBottom: "15px", width: "13px" }}
                      src="/info_icon.svg"
                      alt="info_icon"
                    />
                    <label className={styles.inputLabel}>
                      Receive (estimated): {receivedAmount}
                    </label>
                  </div>
                  <Form.Item
                    style={{ margin: "10px 0 20px 10px", width: "90%" }}
                    name="receive"
                  >
                    <StyledInput
                      className={styles.inputField}
                      style={{ padding: "10px 0px 10px 10px" }}
                      type="number"
                      placeholder="0.0"
                      readOnly
                    />
                  </Form.Item>
                </div>
                <StyledButton
                  className={`${styles.button} ${activeClass}`}
                  onMouseEnter={() => setActiveClass(styles.mouseEnter)}
                  onMouseLeave={() => setActiveClass(styles.mouseLeave)}
                  htmlType="submit"
                  loading={loading || isTransfer}
                >
                  {!isConnected ? (
                    <span>Connect Wallet</span>
                  ) : (
                    <span>{loading || isTransfer ? "Pending" : "Bridge"}</span>
                  )}

                  <ArrowRightMd />
                </StyledButton>
              </Form>
            </div>
          </div>
        </div>
        <ConnectWalletModal openModal={openModal} setOpenModal={setOpenModal} />
        <ConfirmationModal
          setOpenConfirmationModal={setOpenConfirmationModal}
          openConfirmationModal={openConfirmationModal}
          btnAction={handleBridgeModal}
          allowanceAmount={AllowanceAmount}
          loading={loading}
          isApproved={isApproved}
          allowanceCheck={allowanceCheck}
          bridge="bridge"
        />
      </section>
    </>
  );
};

export default Bridge;
